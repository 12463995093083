
@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#hh {
  display: flex;
  justify-content: start;
  font-size: 100px;
}

li.ant-menu-item{
  margin-top: 0 !important;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
  color: #000033!important;
  font-weight: bold;
  font-size:  medium;
}



/* li.ant-menu-item-active{
  color: #000033;
} */



#root, #root > div, #root > div > section {
  min-height: 100vh;
}

ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark{
  display: flex;
  flex-direction: column;
  height: 80%;
  position: relative;
  background-color: white;
  color: black;
}


#mini-logo {
  bottom: 0;
}

.the-end{
  text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -6px;
    
}


.report-body{
  display: flex;
  justify-content: space-around;
  margin-top: 70px;

}

.report-body-in{
  display: flex;
  justify-content: space-around;
  margin-top: 42px;
}

.ant-layout-header {
  height: 150px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px 0 0;
}

#nest-messages .ant-row{
  display: block;
}

.ant-form-item-label{
  overflow: visible;
}

.ant-layout-sider-children{
  background-color: white;
}

#footer{
  color: "#000033";
  font-weight: "bold";
  font-size: "medium";
  text-align: "center";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  margin-bottom: "8px";
  justify-content: space-between;
}

.ant-tabs-tab{
  font-weight: bold;
    font-size: medium;
    color: #000033;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #FE6A00;
}

.ant-tabs-ink-bar{
  background: #FE6A00;
}

#page-titel p{
  font-size: x-large;
  font-weight: bold;
  background-color: white;
  padding: 15px;
  color: #000033;
}

.ant-tabs-tabpane{
  background-color: white;
  padding: 0;
}
/* .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane{
  padding: 5% !important;
} */


.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
    display: inline-block;
    background-color:#FFFF;
    padding: 5px 10px;
    font-size: 1em;
    box-shadow: 0px 2px 48px #00000014;
    border-radius: 50px;
    margin: 0px 10px;
    width: 190px;
    text-align: center;
    border: 1px solid;
    text-transform: uppercase;
    font-weight: bold;
}

.radio-toolbar label:hover {
  background-color: rgb(247, 247, 247);
}



.radio-toolbar input[type="radio"]:checked + label {
    background-color: #000033;
    -moz-box-shadow:  rgb(0 0 50 / 37%) 0px 0px 0px 2px, rgb(6 24 44 / 65%) 0px 4px 6px -1px, rgb(255 255 255 / 8%) 0px 1px 0px inset;
    -webkit-box-shadow: rgb(0 0 50 / 37%) 0px 0px 0px 2px, rgb(6 24 44 / 65%) 0px 4px 6px -1px, rgb(255 255 255 / 8%) 0px 1px 0px inset;
    box-shadow: rgb(0 0 50 / 37%) 0px 0px 0px 2px, rgb(6 24 44 / 65%) 0px 4px 6px -1px, rgb(255 255 255 / 8%) 0px 1px 0px inset;
}

th .anticon svg {
  width: 2em;
  height: 2em;
}

td .anticon svg {
  width: 1.5em;
  height: 1.5em;
}

.ant-layout-footer{
  padding: 14px 50px;
}

/* .ant-form .ant-form-horizontal .login-form{
  display: flex;
    flex-direction: column;
    align-items: center;
    max-width: initial;
    padding-top: 360px;
} */

/* .login-form {
  max-width: 300px;
} */
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}


/*    Login & Register    */

#normal_login{
  display: flex;
    flex-direction: column;
    align-items: center;
    max-width: initial;
    /* padding-top: 40px; */
}

/* .site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
} */

/* .container{
  width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.container{
  position: absolute; width: 100% !important; height: 100% !important; 
}

.container2{
  padding: 0px;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 0px 50px #dedee2;
    margin: 0px 150px;
    margin-top: 240px;
}

/* #root{
  display: flex;
  justify-content: center;
} */

/* .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-16{
  box-shadow: 0px 0px 25px #dedee2;

} */

#register_name{
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 25px #dedee2;
}
#register_email{
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 25px #dedee2;
}
#register_password{
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 25px #dedee2;
}
.container .ant-input-affix-wrapper.ant-input-password{
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 25px #dedee2;
}


.container .ant-row{
  height: 100%;
  justify-content: center;
}

.container .ant-input-affix-wrapper{
  border: none;
  border-radius: 0px;
  box-shadow: 0px 0px 25px #dedee2;
}

.container .ant-form-item-control-input{
  position: relative;
    display: flex;
    align-items: center;
    min-height: 50px;
}

.container .ant-input-affix-wrapper{
  border-radius: 10px;
}

.container .ant-layout-footer {
  padding: 10px 10px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}


#register{
  display: flex;
    flex-direction: column;
    
    max-width: initial;
    padding-top: 5%;
}

.container .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-xs-offset-0.ant-col-sm-16.ant-col-sm-offset-8{
  margin-left: 45px;
}

/*  *end*  Login & Register    */

/*  Add WitList   */

#addWL{
  width: 50px;
  height: 50px;
  align-self: flex-end;
  margin: 15px;
  border-radius: 110px;
  background-color: #000033;
  box-shadow: 0px 0px 10px grey;
  border: solid gray 1px;
}

#addWL p{
  font-size: 22px;
    font-weight: 1000;
}

/*  end  Add WitList   */

/*  update profile company  */

.update{
  width: 83px;
  height: 37px;
  align-self: flex-end;
  margin: 0 16px 110px 0;
  border-radius: 10px;
  background-color: #000033;
  box-shadow: 0px 0px 10px grey;
  border: solid gray 1px;
  /* position: absolute; */
    /* bottom: 1440%; */
    /* left: 200%;
    bottom: 0%;
    right: 0%; */
    position: fixed;
    left: 1170px;
    bottom: 70px;
}

.update p{
  font-size: 15px;
  font-weight: bold;
}


/* end update profile company  */


/*   compleat profile page    */

#completProfile{
  /* display: flex;
  justify-content: space-around;
  border: none;
  margin: 34px 56px 0px 56px;
  padding: 16px 0px;
  box-shadow: 0px 0px 10px #d8d5d5;
  border-radius: 30px; */

  display: flex;
    justify-content: space-around;
    border: none;
    padding: 16px 0px;
    box-shadow: 0px 0px 10px #d8d5d5;
    border-radius: 320px;



}

#logoComplet{
  margin-top: 5px;
    margin-left: 5px;
    position: absolute;
    left: 20px;
    top: 20px;
    width: 75px;
}

#workTimeDateHours{
  display: flex;
  justify-content: space-evenly;
}

#completProfile #mobile{
  border-radius: 15px;
  box-shadow: 0px 0px 7px #e6e4e4;
  border: none;
}

#completProfile .ant-input-group-addon{
  border-radius: 15px;
  box-shadow: 0px 0px 7px #e6e4e4;
  border: none;
}

#completProfile #logo{
  border-radius: 15px;
  box-shadow: 0px 0px 7px #e6e4e4;
  border: none;
}

#completProfile #text{
  border-radius: 15px;
  box-shadow: 0px 0px 7px #e6e4e4;
  border: none;
}

#completProfile #menu{
  border-radius: 15px;
  box-shadow: 0px 0px 7px #e6e4e4;
  border: none;
}

#completProfile .ant-input-number{
  border-radius: 15px;
  box-shadow: 0px 0px 7px #e6e4e4;
  border: none;
}

#completProfile .ant-picker.ant-picker-range{
  border-radius: 15px;
  box-shadow: 0px 0px 7px #e6e4e4;
  border: none;
  margin-right: 35px;
}

#completProfile .ant-select-selector{
  border-radius: 15px;
  box-shadow: 0px 0px 7px #e6e4e4;
  border: none;
}

#completProfile .ant-checkbox-wrapper{
  margin: 0px 10px;
}



/*     Excel BTN      */

.ExcelBTN .ant-btn.ant-btn-primary{
  position: absolute;
  left: 975px;
  bottom: 10px;
  border-radius: 10px;
  font-weight: bold;
}

/*   end  Excel BTN      */

/* chart size */
.report-chart{
  width: "400px";
  height: "200px";
  background-color: "white";
  margin-right: "15px";
  color: "grey";
  margin-bottom: "100px";
  border-left: "solid";
}
/* end chart size */

/* box report */
.box-report{
  display: flex;
  justify-content: space-evenly;
  flex: 3 1 0%;
}
/* end box report */

/* .pares {
    display: flex;
    justify-content: center;
} */
.ExcelBTN .ant-btn.ant-btn-primary {
  position: absolute;
  left: 975px;
  bottom: 10px;
  border-radius: 10px;
  font-weight: bold;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 100px;
  padding-bottom: 30px;
}



/* end content - layout */
.my-customer-p{
  margin-bottom: "-36px";
}

/* content-add-wl */
#content-add-wl{
  display: flex;
    justify-content: space-around;
    border: none;
    box-shadow: 0px 0px 10px #d8d5d5;
    border-radius: 30px;
    width: 80%;
    margin: 5% auto;
    padding: 5% 0; 
}
/* end content-add-wl */

/* btn-add-back-WL */
#btn-add-back-WL{
  /* border-radius: 15px; */
  border-radius: 17px 3px 17px 3px;
}
/* end btn-add-back-WL */

/* box-Average-Guests-size */

/* end box-Average-Guests-size */

/* box-Total-Number-Of-Guests-Visits */
#box-Total-Number-Of-Guests-Visits{
  width: 200px;
  height: 160px;
  background-color: #cde4fa;
  color: #1890ff ;
  border: solid;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin-left: 10px;
}
/* end box-Total-Number-Of-Guests-Visits */

/* box-Total-Number-Of-People-Waiting */
#box-Total-Number-Of-People-Waiting{
  width: 200px;
  height: 160px;
  background-color: #FEF7E7;
  color: rgb(237, 208, 150);
  border: solid;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin-left: 10px;
}
/* box-Total-Number-Of-People-Waiting */

/* box-Total-Number-Of-People-Served */
#box-Total-Number-Of-People-Served{
  width: 200px;
  height: 160px;
  background-color: #EEF9F3;
  border: solid;
  color: #90C7AF;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin: 0 12px;
  background-color: #f3f9f3;
}
/* end box-Total-Number-Of-People-Served */

/* box-Total-Number-of-People-Canceled */
#box-Total-Number-of-People-Canceled{
  width: 200px;
  height: 160px;
  background-color: #FDE7EC;
  border: solid;
  color: #DD6E8B;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
}
/* end box-Total-Number-of-People-Canceled */


#test{
  display: flex;
  justify-content: space-between;
}

#inSide .ant-form-item-control-input-content{
  display: flex;
}

#outSide{
  margin-bottom: -40px;
}

#bb .ant-row {
  display: block;
  flex-flow: row wrap;
}

#dd{
  margin: 0px 18px;
}

#inSide{
  display: flex;
}


.textCenter, .textCenter > div > input{
  text-align: center;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  /* padding: 25px;
  margin: 4px 2px; */
}

/* Might want to wrap a span around your checkbox text */
.checkboxtext
{
  /* Checkbox text */
  font-size: 110%;
  display: inline;
}
/* For tablets: */

/* avgGestStyle */
#avgGestStyle{
  font-size: 8em;
  color: rgb(0, 0, 51);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 25px;
}
/* end avgGestStyle */


@media screen and (min-width: 1024px) {

  /* login pag */
  #hjzLogoImg{
    width: 85%;
    padding-left: 92px;
    /* padding-top: 124px; */
  }
  .container2{
    width: 300px;
    margin-left: 112px;
    margin-top: 109px;
  }
  #hjz{
    width: 60px;
  }
  #hjzSpan{
    font-size: 20px;
  }

 
  /* end Register-form-button */

  /* end login pag */

 /* report pag  */
 
  .report-chart{
    width: 390px;
    margin-top: 60px;
  }
  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark {
    height: 70%;
  }
  .box-report{
    display: flex;
    justify-content: space-evenly;
    flex: 3 1 0%;
    width: 40px;
  }
  .radio-toolbar label {
    display: inline-block;
    background-color: #FFFF;
    padding: 5px 10px;
    font-size: 13px;
    box-shadow: 0px 2px 48px #00000014;
    border-radius: 50px;
    margin: 0px 10px;
    width: 145px;
    text-align: center;
    border: 1px solid;
    text-transform: uppercase;
    font-weight: bold;
}


/* #pares1{
  display: flex;
  flex-direction: row;
  
}
#pares2{
  display: flex;
  flex-direction: row;
  
} */
  /* end report pag  */

  /* my customer */
  .ExcelBTN .ant-btn.ant-btn-primary {
    position: absolute;
    left: 594px;
    bottom: 64px;
    border-radius: 10px;
    font-weight: bold;
}
  /* end my customer */

  /* stting updat */

  /* end stting updat */

  /* content - layout */

/* end content - layout */ 

/* content-add-wl */

/* end content-add-wl */

/* compleat profile */
#completProfile {
  display: flex;
  justify-content: space-around;
  border: none;
  padding: 16px 0px;
  box-shadow: 0px 0px 30px #d8d5d5;
  border-radius: 320px;
  width: 100%;
  position: absolute;
  top: 55px;
}
/* end compleat profile */

/* submit-btn-compleat */
#submit-btn-compleat{
  color: #fff;
    border-color: #1890ff;
    background: #000033;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    /* border-radius: 15px; */
    border-radius: 17px 3px 17px 3px;
}
/* end submit-btn-compleat */

.the-end {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}


/* end box-Average-Guests-size */

/* box-Total-Number-Of-Guests-Visits */
#box-Total-Number-Of-Guests-Visits{
  width: 150px;
  height: 150px;
  background-color: #cde4fa;
  color: #1890ff ;
  border: solid;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin-left: 10px;
}
/* end box-Total-Number-Of-Guests-Visits */

/* box-Total-Number-Of-People-Waiting */
#box-Total-Number-Of-People-Waiting{
  width: 150px;
  height: 150px;
  background-color: #FEF7E7;
  color: rgb(237, 208, 150);
  border: solid;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin-left: 10px;
}
/* box-Total-Number-Of-People-Waiting */

/* box-Total-Number-Of-People-Served */
#box-Total-Number-Of-People-Served{
  width: 150px;
  height: 150px;
  background-color: #EEF9F3;
  border: solid;
  color: #90C7AF;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin: 0 12px;
  background-color: #f3f9f3;
}
/* end box-Total-Number-Of-People-Served */

/* box-Total-Number-of-People-Canceled */
#box-Total-Number-of-People-Canceled{
  width: 150px;
  height: 150px;
  background-color: #FDE7EC;
  border: solid;
  color: #DD6E8B;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
}
/* end box-Total-Number-of-People-Canceled */

/* switch */
#switch{
  position: absolute;
  bottom: 610px;
  left: 803px;
  font-weight: bold;
  color: #000033;
  font-size: x-large;
}
/* end switch */

/*  Add WitList   */

#addWL{
  width: 50px;
  height: 50px;
  align-self: flex-end;
  margin: 15px;
  border-radius: 110px;
  background-color: #000033;
  box-shadow: 0px 0px 10px grey;
  border: solid gray 1px;
  position: absolute;
  top: 691px;
  right: -4px;
}

#addWL p{
  font-size: 22px;
    font-weight: 1000;
}

/*  end  Add WitList   */

/* menuItem */
#menuItem{
  font-size: large;
}
/* end menuItem */

/* avgGestStyle */
#avgGestStyle{
  font-size: 7em;
    color: rgb(0, 0, 51);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 16px;
}
/* end avgGestStyle */

}

@media screen and (min-width: 1366px) {

  /* login pag */
  #hjzLogoImg{
    width: 90%;
    padding-left: 90px;
    padding-top: 25px;
  }

  .container2{
    width: 375px;
    margin-left: 145px;
    margin-top: 215px;
  }
  #hjz {
    width: 80px;
  }
  #hjzSpan{
    font-size: 26px;
  }

   
  /* end Register-form-button */


  /* end login pag */
  /* report pag  */
 
  .report-chart{
    width: 500px;
    margin-top: 90px;
  }
  .radio-toolbar label {
    display: inline-block;
    background-color: #FFFF;
    padding: 5px 10px;
    font-size: 16px;
    box-shadow: 0px 2px 48px #00000014;
    border-radius: 50px;
    margin: 0px 10px;
    width: 190px;
    text-align: center;
    border: 1px solid;
    text-transform: uppercase;
    font-weight: bold;
}

  /* end report pag */
  /* my customer */
  .ExcelBTN .ant-btn.ant-btn-primary {
    position: absolute;
    left: 936px;
    bottom: 60px;
    border-radius: 10px;
    font-weight: bold;
}
  /* end my customer */

 /* stting updat */

/* end stting updat */ 

 /* content - layout */

 
/* end content - layout */ 

/* content-add-wl */

/* end content-add-wl */

/* compleat profile */
#completProfile {
  display: flex;
  justify-content: space-around;
  border: none;
  padding: 16px 0px;
  box-shadow: 0px 0px 40px #d8d5d5;
  border-radius: 350px;
  width: 100%;
  position: absolute;
  top: 175px;
}
/* end compleat profile */
.the-end {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}


/* end box-Average-Guests-size */

/* box-Total-Number-Of-Guests-Visits */
#box-Total-Number-Of-Guests-Visits{
  width: 200px;
  height: 160px;
  background-color: #cde4fa;
  color: #1890ff ;
  border: solid;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin-left: 10px;
}
/* end box-Total-Number-Of-Guests-Visits */

/* box-Total-Number-Of-People-Waiting */
#box-Total-Number-Of-People-Waiting{
  width: 200px;
  height: 160px;
  background-color: #FEF7E7;
  color: rgb(237, 208, 150);
  border: solid;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin-left: 10px;
}
/* box-Total-Number-Of-People-Waiting */

/* box-Total-Number-Of-People-Served */
#box-Total-Number-Of-People-Served{
  width: 200px;
  height: 160px;
  background-color: #EEF9F3;
  border: solid;
  color: #90C7AF;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
  margin: 0 12px;
  background-color: #f3f9f3;
}
/* end box-Total-Number-Of-People-Served */

/* box-Total-Number-of-People-Canceled */
#box-Total-Number-of-People-Canceled{
  width: 200px;
  height: 160px;
  background-color: #FDE7EC;
  border: solid;
  color: #DD6E8B;
  border-radius: 25px;
  display: Grid;
  grid-template-rows: 1fr 1fr;
}
/* end box-Total-Number-of-People-Canceled */

/* switch */
#switch{
  position: absolute;
  bottom: 872px;
  left: 1150px;
  font-weight: bold;
  color: #000033;
  font-size: x-large;
}
/* end switch */

/*  Add WitList   */

#addWL{
  width: 50px;
  height: 50px;
  align-self: flex-end;
  margin: 15px;
  border-radius: 110px;
  background-color: #000033;
  box-shadow: 0px 0px 10px grey;
  border: solid gray 1px;
  position: absolute;
  top: 947px;
  right: 0px;
}

#addWL p{
  font-size: 22px;
    font-weight: 1000;
}

/*  end  Add WitList   */

/* menuItem */
#menuItem{
  font-size: large;
}
/* end menuItem */

/* avgGestStyle */
#avgGestStyle{
  font-size: 8em;
  color: rgb(0, 0, 51);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 25px;
}
/* end avgGestStyle */


}

@media screen and (min-width: 1483px) {

    /* login pag */
    #hjzLogoImg{
      width: 90%;
      padding-left: 90px;
      padding-top: 25px;
    }
  
    .container2{
      width: 375px;
      margin-left: 145px;
      margin-top: 215px;
    }
    #hjz {
      width: 80px;
    }
    #hjzSpan{
      font-size: 26px;
    }

     /* Register-form-button */

  /* end Register-form-button */

    /* end login pag */
    /* report pag  */
 
    .report-chart{
      width: 450px;
      margin-top: 40px;
    }
    .radio-toolbar label {
      display: inline-block;
      background-color: #FFFF;
      padding: 5px 10px;
      font-size: 16px;
      box-shadow: 0px 2px 48px #00000014;
      border-radius: 50px;
      margin: 0px 10px;
      width: 190px;
      text-align: center;
      border: 1px solid;
      text-transform: uppercase;
      font-weight: bold;
  }
 
  
    /* end report pag */
    /* my customer */
    .ExcelBTN .ant-btn.ant-btn-primary {
      position: absolute;
      left: 936px;
      bottom: 60px;
      border-radius: 10px;
      font-weight: bold;
  }
    /* end my customer */
  
   /* stting updat */

  /* end stting updat */ 
  
 
  
  .the-end {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
}
  /* end content - layout */ 
  
  /* content-add-wl */
 
  /* end content-add-wl */
  
  /* compleat profile */
  #completProfile {
    display: flex;
    justify-content: space-around;
    border: none;
    padding: 16px 0px;
    box-shadow: 0px 0px 40px #d8d5d5;
    border-radius: 350px;
    width: 100%;
    position: absolute;
    top: 95px;
    font-weight: 500;
  }
  /* end compleat profile */

  /* my customer */
  .ExcelBTN .ant-btn.ant-btn-primary {
    position: absolute;
    left: 1071px;
    bottom: 60px;
    border-radius: 10px;
    font-weight: bold;
}
  /* end my customer */

  /* switch */
#switch{
  position: absolute;
  bottom: 697px;
  left: 1277px;
  font-weight: bold;
  color: #000033;
  font-size: x-large;
}
/* end switch */

/*  Add WitList   */

#addWL{
  width: 50px;
  height: 50px;
  align-self: flex-end;
  margin: 15px;
  border-radius: 110px;
  background-color: #000033;
  box-shadow: 0px 0px 10px grey;
  border: solid gray 1px;
  position: absolute;
  top: 778px;
  right: 8px;
}

#addWL p{
  font-size: 22px;
    font-weight: 1000;
}

/*  end  Add WitList   */

/* menuItem */
#menuItem{
  font-size: large;
}
/* end menuItem */

/* avgGestStyle */
#avgGestStyle{
  font-size: 8em;
  color: rgb(0, 0, 51);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 25px;
}
/* end avgGestStyle */

}


/* .ant-checkbox-inner::after , .ant-form-item input[type='radio'], .ant-form-item input[type='checkbox']{
  height: 10px;
  width: 10px;
} */


.ant-row > .ant-col-10{
  min-width: 100%;
}



/* ========== version 2 ===============*/



/* ========== general 2 ===============*/


h1, h2, h3, h4, h5, h6{
  margin:0;
  padding:0
}

.hjz-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.register-body{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-container-register-body{
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.shadow-form {
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;
  border-radius: 5%;
  width: 45%;
  padding: 3% 0 3% 0;
}

.btn-darkblue {
  background-color: #000033;
  border-radius: "10px";
  width: 70%;
  height: 45px;
}


.login-field{
  width: 80%;
}

.login-sub-body{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 3px;
}

/*******  setting component *********/
 .update{
  position: initial !important;
  margin: 0 !important;
}

.update > p {
  margin-bottom: 0;
}

.second-col > .ant-form-item{
  position: absolute;
  right: 25px;
  top: 15px;
}


/*******  main component *********/
.main-component{
  height: 100vh;
  width: 100%;
  background-color: #FFF;

}

.sub-component{
  height: 94vh;
  min-height: 94vh;
  max-height: 94vh;
  overflow: hidden;
}
.ant-layout {
  background-color: initial;
}
/* menu */
.ant-menu{
  background-color: #FAFAFA;
}


.item-menu{
  width: 65px;
  max-width: 65px;
  min-width: 65px;
  height: 70px;
  max-height: 70px;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 !important;
}

.item-menu .ant-menu-title-content{
  margin: 0 !important;
  padding: 0 !important;
}

.item-menu > .ant-menu-title-content {
  flex: none !important;
}


.item-menu > .anticon {
  font-size: 1.8em !important;
}

#menuItem{
  font-size: 1em;
  font-weight: bold;
}

.ant-menu{
  height: 94vh !important;
}

#parent-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#first-child-menu{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#first-child-menu, #second-child-menu {
  height: 50%;
}

#second-child-menu{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#logout-icon{
  color: #FE6A00 !important
}


#sider{
    flex: 0 0 65px !important;
    max-width: 65px !important;
    min-width: 65px !important;
    width: 65px !important;
}


/*******  header component *********/
#header-component {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 6vh;
  background: #000033;
  display: block;
  color: white;
  padding: 0 1%;
}


.header-title-branch{
  font-size: 0.5em;
}

#header-component h1, #header-component  h4, #header-component h5{
  color: white;
}

#header-component h1 {
  line-height: 1em;
}
/* #header-component .ant-switch{
  background-color: #FFF ;
}
#header-component .ant-switch-handle::before{
  background-color: #000033;
}

#header-component .ant-switch-inner{
  color: #000033;
  font-weight: bold;
} */


/*******  waitlist component *********/
.ant-pagination {
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  right: auto;
  left: auto;
  margin: 0 auto;
  width: 90%;
}

.ant-table-pagination.ant-pagination{
  margin: 10px 0 !important;
}
/* show */
.style-show-waitlist {
  display: flex;
}

.style-show-waitlist > h1 {
  font-weight: bolder;
  /* margin-right: 1%; */
}


.content-layout{
  /* padding-top: 3% !important; */
  justify-content: space-between;
  background-color: white;
  /* border-radius: 25px; */
  /* overflow: hidden; */
  /* height: 96vh !important;
  min-height: 96vh !important;
  max-height: 96vh !important; */
}

.waitlist-table .ant-table-cell:first-child  {
  text-align: center !important;
  font-size: 0.4em !important;
}

.stars {
  font-size: 0.5em !important;
}

.ant-rate {
    font-size: 3em !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.ant-rate-star{
  margin: 0 !important;
  margin-left: 2% !important;
}


/*******  Menu General *********/
.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
  transition: border-color 0.9s, background 0.2s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.box-add{
  margin-top: 5%;
  justify-content: center;
  background: #FAFAFA;
  border-bottom: 1px solid;
}
.add {
  justify-content: center;
  background-color: #FAFAFA;
}

#addGuest{
  width: 50px;
  height: 50px;
  border-radius: 110px;
  background-color: #000033;
  box-shadow: 0px 0px 10px grey;
  border: solid gray 1px;
}

#addGuest p{
  font-size: 22px;
  font-weight: 1000;
}

/*******  Setting General *********/
.ant-menu-item-selected a:hover{
  background-color: none !important
}

.ant-menu-item-selected,.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected  span{
  color: #FFF !important;
  background: #000032 !important;
}

 .ant-menu-item:hover{
  color: inherit !important;
  /* background: #000032 !important; */
}

.ant-menu-item:active {
  color: inherit !important;
  background: #000032 !important;
}
.sub-menu {
  width:100%;
}

.sub-menu > .ant-tabs-nav{
  font-size: 1.5em;
  width:25%;
  
}
.sub-menu > .ant-tabs-content-holder{
  width:75%;
}

.ant-tabs-tab:first-child{
  border-bottom: 2px solid #FE6A00;
}

.ant-tabs-tab{
  border-bottom: 1px solid black;
  padding: 3% 0 3% 5% !important;
  text-align: left !important;
  margin: 0 !important;
}


.sub-component-title{
  font-size: 1.5em;
  margin: 2% 5%;
  color: #FE6A00;
  font-weight: bold;
}

.title-setting > h1{
  color: #FE6A00;
  font-weight: bold;
  font-size: 1.2em;
}


.sub-title-setting-menu{
  font-size: 1em;
  font-weight: medium;
}

.ant-tabs-content > .ant-tabs-tabpane {
  padding:20px !important;
}


.title-name-of-setting-menu{
  font-size: 1.5em;
  color: #FE6A00;
  font-weight: bolder;
}

/*******  Setting components *********/
.update-index{
  z-index: 1;
}
.ant-tabs-content-holder{
  overflow-y: auto !important;
}

.lable-form >  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display: none !important
}

.lable-form .ant-form-item-label {
  padding: 0;
}


.form-container  input, .lable-form {
  width: 100%;
}

.first-col {
  margin-right: 5%;
}
 
.second-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.first-col, .second-col {
  width:50%
}

.group-field{
  width: 100%;
  margin-bottom:5%;
}

.group-field > h3 {
  font-weight: bold;
  color: #000033;
}

.form-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}


.social-media-field .ant-form-item-control-input{
  margin-top: 2%;
}



/*******  branch setting component *********/


.flex-box{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5%;
}

.flex-box .ant-form-item-control{
  text-align: left;
      margin-right: 50px;
}


.group-field .ant-checkbox-group{
  width: 100%;
}

.group-field .ant-checkbox-group .ant-row {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}


.reservationTypes .ant-checkbox-inner::after {
  width: 4px;
  height: 9px;
}

.reservationTypes .ant-checkbox-inner, .ant-checkbox-input{
  transform: scale(1.5)
}

/*******  work-hours setting component *********/

.work-hours {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

}

.work-hours  .ant-row {
  display: flex !important;
  justify-content:center;
  align-items:center
}


.work-hours .ant-picker-range, .work-hours .ant-form{
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-hours > .first-col{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
}
.qr-box {
  display: flex;
  align-items: center;
  align-self: center;
}

/*******  sub setting component *********/
.sub{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}




/*******  Employees setting component *********/
.body-waitlist{
  padding-top: 2% !important;
}

.branches .ant-table-row td:nth-child(4) {
  display: flex ;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
/*******  waitlist component *********/
.header-waitlist {
  margin-bottom: 2%;
}

.radio-toolbar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* .waitlist-table .ant-table-body {
  min-height: inherit !important;
  height: 100vh;
} */




/*******  new Branch component *********/

.container-form{
  width: 95%;
  margin: 0 auto;
  overflow: auto;
}


.container-form .second-col > .ant-form-item{
  right: 55px;
  top: 75px;
}

/*******  new waitlist component *********/

.hidden{
  display: none;
}

.margin-auto{
  margin: 0 auto;
}

.create-waitlist{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addGuest .ant-modal-footer{
  text-align: center;
}
.addGuest .ant-form-item-required , .addGuest .ant-form-item-label{
  font-weight: bold;
}

.addGuest  .ant-input-number {
  width:100%;
}

.show-addWaitlist{
  display: flex;
}

.collection-create-form_last-form-item {
  margin-bottom: 0;
}

/*******  ALL guest component *********/



.main-reports {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.body-reports {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  width: 100%;
  align-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-around;
}

.sub-main-reports {
  width: 100%;
  display: flex;
}




.box-reports {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}


.sub-box-reports{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.chart-reports{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.reports-chart {
  width: 45%;
  margin-top: 5%;
  font-size:2em;
}


#box-Average-Guests-size{
  /* width: 150px; */ 
  /* height: 150px; */
  /* background-color: white; */
  flex-direction: column;
  border-right: #dedede solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  padding: 0 2%;
}

.title-avg-guests {
  font-weight: bolder;
  font-size: 1.5em;
}







/*  Media  for Ipad mini -  vertial*/
@media screen and (max-width: 830px) {
  .shadow-form{
    width:60%;
  }

  .radio-toolbar label{
    width:140px;
    margin: 0px 5px;
    padding: 5px 0px;

  }

  .form-container{
    flex-direction: column !important;
    align-items: center;
    width: 100%;
  }
  .first-col, .second-col {
    width:80%;
    margin-right:0;
  }

  .reports-chart {
    width: 65%;
    margin-top: 5%;
    font-size: 2em;
  }

  #box-Total-Number-Of-People-Served, #box-Total-Number-Of-Guests-Visits  {
    margin-bottom: 4% !important;
  }

}


/*  Media  for Ipad */





 /* Media  for desktop */
@media screen and (min-width: 1200px) {
  .shadow-form{
    width:30%;
  }

  .work-hours > .first-col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
  }

  .reports-chart {
    width: 35%;
    margin-top: 5%;
    font-size:2em;
  }
  
  

}